import { Injectable, inject } from "@angular/core";

import { IAuthenticationService, UserIdentity } from "@logex/framework/lg-application";
import { firstValueFrom, lastValueFrom } from "rxjs";

import { Auth0AuthorizationService } from "./auth0-authorization.service";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "src/app/shared/services/config.service";

@Injectable()
export class Auth0AuthenticationService implements IAuthenticationService {
    private _httpClient = inject(HttpClient);
    private _configService = inject(ConfigService);

    get loggedIn(): boolean {
        return this._authorizationService.loggedIn ?? false;
    }

    get user(): UserIdentity {
        const user = this._authorizationService.currentUserProfile;
        return {
            id: user?.email ?? "",
            login: user?.email ?? "",
            name: user?.name ?? "",
        };
    }

    constructor(private _authorizationService: Auth0AuthorizationService) {}

    login(): Promise<boolean> {
        return this._authorizationService.login();
    }

    isLoggedIn(): Promise<boolean> {
        return firstValueFrom(this._authorizationService.isAuthenticated$);
    }

    logout(): Promise<void> {
        return this._authorizationService.logout();
    }

    endImpersonation(): Promise<void> {
        const apiUrl = this._configService.configuration.authApiUrl;
        return lastValueFrom(this._httpClient.delete<void>(`${apiUrl}api/v3/impersonation/stop`));
    }
}
