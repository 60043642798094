import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TranslateModule, TranslateCompiler } from "@ngx-translate/core";

import {
    LgFiltersetStateGateway,
    LgLayoutModule,
    LgLocalStorageFwUiStateService,
    LG_FW_UI_STATE_SERVICE,
} from "@logex/framework/lg-layout";
import { LgConsoleConfiguration } from "@logex/framework/core";
import { UiCoreModule } from "@logex/framework/ui-core";
import {
    LG_USER_INFO,
    LG_NAVIGATION,
    LG_APP_CONFIGURATION,
    LG_APP_SESSION,
    IAppSession,
    LG_APP_INFO,
    LgMatTrackingService,
    LG_MATOMO_CONFIGURATION,
    LG_AUTHENTICATION_SERVICE,
} from "@logex/framework/lg-application";
import {
    ReferenceTranslateCompiler,
    LgLocalizationModule,
    LG_LOCALIZATION_SETTINGS,
    useMessageFormatLocales,
} from "@logex/framework/lg-localization";
import { LgFiltersetModule } from "@logex/framework/lg-filterset";

import { AUTH0_CONFIG, GliAuthorizationModule, Auth0InterceptorService } from "@authorization";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppLocalizationSettings } from "./shared/services";
import { AppUser } from "./shared/types/app-user";
import { navigation } from "./shared/app-navigation";
import { PlmAppConfiguration } from "./shared/types/plm-app-configuration";
import { FilterFactory } from "./shared/filter-factory";
import { AppStartupService } from "./shared/services/app-startup.service";
import { ConfigService } from "./shared/services/config.service";
import { AccessDeniedComponent } from "./access-denied-page/access-denied.component";
import { MatomoUtilService } from "./authorized/services/matomo-util.service";
import { MatomoSharedConfiguration } from "./shared/matomo-shared-configuration";
import { UserProfileService } from "./shared/services/user-profile.service";
import { Auth0AuthenticationService } from "./authorization/lib/auth0-authentication.service";

const dummySession: IAppSession = {
    clientId: 10003,
    client: {
        id: 123,
        code: "123",
        name: "Test",
    },
    scenarioId: 1,
};

@NgModule({
    declarations: [AppComponent, AccessDeniedComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        UiCoreModule,
        AppRoutingModule,
        GliAuthorizationModule.forRoot(),
        LgFiltersetModule.forRoot(),
        LgLayoutModule,
        TranslateModule.forRoot({
            compiler: { provide: TranslateCompiler, useClass: ReferenceTranslateCompiler },
        }),
        LgLocalizationModule.forRoot({
            localizationUrlPrefix: "localization",
        }),
    ],
    providers: [
        LgFiltersetStateGateway,
        {
            provide: ConfigService,
            useClass: ConfigService,
        },
        {
            provide: LG_AUTHENTICATION_SERVICE,
            useClass: Auth0AuthenticationService,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (startup: AppStartupService) => startup.initialize(),
            deps: [AppStartupService, ConfigService],
            multi: true,
        },
        {
            provide: AUTH0_CONFIG,
            useFactory: (startup: ConfigService) =>
                startup.configurationPromise.then(config => config.auth0),
            deps: [ConfigService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Auth0InterceptorService,
            multi: true,
        },
        {
            provide: LgConsoleConfiguration,
            useFactory: () => {
                const config = new LgConsoleConfiguration();
                return config;
            },
        },
        {
            provide: LG_LOCALIZATION_SETTINGS,
            useClass: AppLocalizationSettings,
        },
        useMessageFormatLocales(["nl", "nl-NL", "en", "en-GB"]),

        {
            provide: LG_USER_INFO,
            useValue: new AppUser(),
        },
        {
            provide: LG_NAVIGATION,
            useFactory: () => navigation(),
        },
        {
            provide: LG_APP_CONFIGURATION,
            useValue: new PlmAppConfiguration(),
        },
        {
            provide: LG_APP_SESSION,
            useValue: dummySession,
        },
        {
            provide: LG_APP_INFO,
            useValue: {
                environment: "test",
                fullAppName: "Patients like me",
                productId: "plm",
                toolInstanceName: "patients",
                versionNumber: "0.0.1",
                overrideCurrencyPrefix: "",
                overrideCurrencySuffix: "",

                doNotDoGaTracking: () => false,
                isProduction: () => false,
            },
        },
        {
            provide: LG_FW_UI_STATE_SERVICE,
            useClass: LgLocalStorageFwUiStateService,
        },
        FilterFactory,
        {
            provide: LG_MATOMO_CONFIGURATION,
            useValue: new MatomoSharedConfiguration(),
        },
        LgMatTrackingService,
        MatomoUtilService,
        UserProfileService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
