import { extend, findLast } from "lodash";
import { Injectable, TemplateRef, Injector, OnDestroy, inject } from "@angular/core";
import { ComponentPortal, ComponentType, TemplatePortal } from "@angular/cdk/portal";
import { filter, take, takeUntil } from "rxjs/operators";

import { LgOverlayService, IOverlayResultApi } from "../lg-overlay/lg-overlay.service";
import { IDialogOptions, LG_DIALOG_DATA, IDialogShowFinalizer } from "./lg-dialog.types";
import { LgDialogHolderComponent } from "./lg-dialog-holder.component";
import { LgDialogRef } from "./lg-dialog-ref";
import { GuardsCheckEnd, Router } from "@angular/router";
import { Subject } from "rxjs";

const DEFAULT_DIALOG_OPTIONS = {
    title: "Dialog",
    allowClose: true,
    parameters: {}
};

const OVERLAY_OPTIONS = {
    class: "lg-overlay__disabled",
    hasBackdrop: true,
    trapFocus: true
};

@Injectable()
export class LgDialogService implements OnDestroy {
    private _injector = inject(Injector);
    private _overlayService = inject(LgOverlayService);
    private _parentService = inject(LgDialogService, { optional: true, skipSelf: true });

    constructor() {
        const router = inject(Router, { optional: true });

        if (this._parentService) {
            this._holderInstances = this._parentService._holderInstances;
            this._stack = this._parentService._stack;
        } else {
            this._holderInstances = {};
            this._stack = [];
            if (router) {
                router.events
                    .pipe(
                        // we should know the navigation will happen (ignoring errors), but while the old component is still visible
                        filter(event => event instanceof GuardsCheckEnd),
                        takeUntil(this._destroyed$)
                    )
                    .subscribe(() => this._onNavigation());
            }
        }
    }

    private _idCounter = 0;
    private _holderInstances: Record<string, LgDialogHolderComponent>;
    private _stack: Array<LgDialogRef<unknown>>;
    private readonly _destroyed$ = new Subject<void>();

    public show<T, D = any>(
        componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
        options: IDialogOptions<D>,
        dialogOptionsFinalizeCb?: IDialogShowFinalizer<T, D>
    ): LgDialogRef<T> {
        const dialogId = this._getDialogId();
        const dialogOptions: IDialogOptions<D> = extend({}, DEFAULT_DIALOG_OPTIONS, options);

        // 1. show overlay
        const overlay: IOverlayResultApi = this._overlayService.show(dialogId, OVERLAY_OPTIONS);
        const dialogHolderComponentPortal = new ComponentPortal(
            LgDialogHolderComponent,
            dialogOptions.viewContainerRef
        );
        // 2. attach dialog holder portal to overlay and get reference to created LgDialogHolderComponent instance
        const dialogHolderComponentInstance: LgDialogHolderComponent = overlay.overlayRef.attach(
            dialogHolderComponentPortal
        ).instance;

        // 3. Create dialogRef which internally subscribes to dialogHolderComponent._beforeClosed
        const dialogRef = new LgDialogRef<T>(
            dialogId,
            dialogOptions,
            overlay,
            dialogHolderComponentInstance
        );
        this._stack.push(dialogRef);

        // 4. Attach component or templateRef to dialogHolderComponent
        if (componentOrTemplateRef instanceof TemplateRef) {
            const templatePortal = new TemplatePortal<T>(componentOrTemplateRef, null, <any>{
                $implicit: dialogOptions.data,
                dialogRef
            });
            dialogHolderComponentInstance._attachTemplate(templatePortal);
        } else {
            const injector = this._createInjector<T>(
                dialogOptions,
                dialogRef,
                dialogHolderComponentInstance
            );
            const componentPortal = new ComponentPortal(
                componentOrTemplateRef,
                undefined,
                injector
            );
            dialogRef.componentInstance =
                dialogHolderComponentInstance._attachComponent(componentPortal).instance;
        }

        // 5. Get final dialog options. If dialogOptionsFinalizeCb is provided, it can modify dialogOptions before they are finally applied
        const optionsFinalizedSuccessfully = this._finalizeDialogOptions(
            dialogOptionsFinalizeCb,
            dialogOptions,
            dialogRef.componentInstance,
            (finalOptions: IDialogOptions<D>) => {
                finalOptions = finalOptions || dialogOptions; // falsy finalOptions are possible in case of incorrectly implemented dialogOptionsFinalizeCb

                dialogRef.applyFinalizedOptions(finalOptions);
                dialogHolderComponentInstance.initialize(
                    finalOptions,
                    finalOptions.relatedTo && this._holderInstances[finalOptions.relatedTo.id],
                    overlay.focusFirstTabbableElement
                );

                this._holderInstances[dialogId] = dialogHolderComponentInstance;

                dialogRef
                    .beforeClosed()
                    .pipe(take(1))
                    .subscribe(() => {
                        delete this._holderInstances[dialogId];
                        this._stack.splice(this._stack.indexOf(dialogRef), 1);
                    });
            }
        );

        if (!optionsFinalizedSuccessfully) {
            dialogHolderComponentInstance.initialize(dialogOptions, null, () => false);
            dialogRef.close(true);
            return null;
        }

        return dialogRef;
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    private _getDialogId(): string {
        if (this._parentService) return this._parentService._getDialogId();

        return "Dialog" + ++this._idCounter;
    }

    private _finalizeDialogOptions<T, D>(
        optionsFinalizerFn: IDialogShowFinalizer<T, D> | undefined,
        dialogOptions: IDialogOptions<D>,
        dialogComponentInstance: T,
        onOptionsFinalized: (finalizedOptions: IDialogOptions<D>) => void
    ): boolean {
        if (!optionsFinalizerFn) {
            onOptionsFinalized(dialogOptions);
            return true;
        }

        try {
            optionsFinalizerFn(dialogOptions, dialogComponentInstance, onOptionsFinalized);
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    private _createInjector<T>(
        options: IDialogOptions<any>,
        dialogRef: LgDialogRef<T>,
        holder: LgDialogHolderComponent
    ): Injector {
        const userInjector = options.viewContainerRef && options.viewContainerRef.injector;
        return Injector.create({
            parent: userInjector || this._injector,
            providers: [
                {
                    provide: LgDialogHolderComponent,
                    useValue: holder
                },
                {
                    provide: LG_DIALOG_DATA,
                    useValue: options.data
                },
                {
                    provide: LgDialogRef,
                    useValue: dialogRef
                }
            ]
        });
    }

    private _onNavigation(): void {
        // close the dialogs in loop in case the operation itself causes cascade of changes
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const dialog = findLast(
                this._stack,
                ref => ref.visible && (ref.options.forceCloseOnNavigation ?? true)
            );
            if (!dialog) break;
            dialog.close(null, true);
        }
    }
}
