import {
    IFilterGroup,
    RegistryConfig,
    ITreatment,
} from "src/app/authorized/services/registry.types";

export const enum DcraSubsets {
    RectalCancer = "rectal-cancer",
    ColonCancer = "colon-cancer",
}

export const enum DcraFilters {
    // values also used in API queries
    // Basic
    Gender = "gender",
    Age = "age",
    AsaScore = "asaScore",
    Bmi = "bmi",
    AdditionalResection = "additionalResection",

    // Comprehensive
    CTstage = "ctStage",
    CMStage = "cmStage",
    RadiationOrChemo = "radiationOrChemotherapy",
}

const registryLcPrefix = "APP._Registries.DCRA";
const filtersLcPrefix = registryLcPrefix + ".Filters";

const basicLcPrefix = filtersLcPrefix + ".Basic";
const basicFilterGroup = {
    groupNameLc: basicLcPrefix + ".GroupName",
    filters: {
        [DcraFilters.Gender]: {
            nameLc: basicLcPrefix + ".Gender",
            options: [
                {
                    id: 1,
                    nameLc: basicLcPrefix + ".Gender__options.Male",
                },
                {
                    id: 2,
                    nameLc: basicLcPrefix + ".Gender__options.Female",
                },
            ],
        },
        [DcraFilters.Age]: {
            nameLc: basicLcPrefix + ".Age",
            options: [
                {
                    id: 1,
                    nameLc: basicLcPrefix + ".Age__options.<65",
                },
                {
                    id: 2,
                    nameLc: basicLcPrefix + ".Age__options.65-75",
                },
                {
                    id: 3,
                    nameLc: basicLcPrefix + ".Age__options.75+",
                },
            ],
        },
        [DcraFilters.AsaScore]: {
            nameLc: basicLcPrefix + ".AsaScore",
            options: [
                {
                    id: 1,
                    nameLc: basicLcPrefix + ".AsaScore__options.ClassesOneTwo",
                },
                {
                    id: 2,
                    nameLc: basicLcPrefix + ".AsaScore__options.ClassesThreeFour",
                },
            ],
        },
        [DcraFilters.Bmi]: {
            nameLc: basicLcPrefix + ".Bmi",
            options: [
                {
                    id: 1,
                    nameLc: basicLcPrefix + ".Bmi__options.Underweight",
                },
                {
                    id: 2,
                    nameLc: basicLcPrefix + ".Bmi__options.Normal",
                },
                {
                    id: 3,
                    nameLc: basicLcPrefix + ".Bmi__options.Overweight",
                },
                {
                    id: 4,
                    nameLc: basicLcPrefix + ".Bmi__options.Obese",
                },
            ],
        },
        [DcraFilters.AdditionalResection]: {
            nameLc: basicLcPrefix + ".AdditionalResection",
            options: [
                {
                    id: 1,
                    nameLc: basicLcPrefix + ".AdditionalResection__options.No",
                },
                {
                    id: 2,
                    nameLc: basicLcPrefix + ".AdditionalResection__options.Yes",
                },
            ],
        },
    },
};

const comprehensiveLcPrefix = filtersLcPrefix + ".Comprehensive";
const comprehensiveFilterGroup = {
    groupNameLc: comprehensiveLcPrefix + ".GroupName",
    filters: {
        [DcraFilters.CTstage]: {
            nameLc: comprehensiveLcPrefix + ".cTstage",
            options: [
                {
                    id: 1,
                    nameLc: comprehensiveLcPrefix + ".cTstage__options.cT1cT2",
                },
                {
                    id: 2,
                    nameLc: comprehensiveLcPrefix + ".cTstage__options.cT3",
                },
                {
                    id: 3,
                    nameLc: comprehensiveLcPrefix + ".cTstage__options.cT4",
                },
                {
                    id: 4,
                    nameLc: comprehensiveLcPrefix + ".cTstage__options.cTX",
                },
            ],
        },
        [DcraFilters.CMStage]: {
            nameLc: comprehensiveLcPrefix + ".cMstage",
            options: [
                {
                    id: 1,
                    nameLc: comprehensiveLcPrefix + ".cMstage__options.cM0xMX",
                },
                {
                    id: 2,
                    nameLc: comprehensiveLcPrefix + ".cMstage__options.cM1",
                },
            ],
        },
        [DcraFilters.RadiationOrChemo]: {
            nameLc: comprehensiveLcPrefix + ".RadiationChemoBefore",
            options: [
                {
                    id: 0,
                    nameLc: comprehensiveLcPrefix + ".RadiationChemoBefore__options.No",
                },
                {
                    id: 1,
                    nameLc: comprehensiveLcPrefix + ".RadiationChemoBefore__options.Preop55Gy",
                },
                {
                    id: 2,
                    nameLc:
                        comprehensiveLcPrefix +
                        ".RadiationChemoBefore__options.PreopChemoradiotherapy",
                },
            ],
        },
    },
};

const filters: IFilterGroup[] = [basicFilterGroup, comprehensiveFilterGroup];

const dcraTreatments: ITreatment[] = [
    { id: "ostomy", routePath: "ostomy", apiValue: "ostomy" },
    {
        id: "anastamosisConstructedWithTemporaryOstomy",
        routePath: "temporary-ostomy",
        apiValue: "temporaryOstomy",
    },
    {
        id: "anastamosisConstructedDuringSurgeryWithoutOstomy",
        routePath: "no-ostomy",
        apiValue: "withoutOstomy",
    },
];

export const dcraConfig: RegistryConfig = {
    name: "DCRA",
    nameLc: registryLcPrefix + ".Name",
    subsets: [
        // {
        //     nameLc: registryLcPrefix + ".Subsets.ColonCancer",
        //     value: DcraSubsets.ColonCancer,
        //     filters,
        // },
        {
            nameLc: registryLcPrefix + ".Subsets.RectalCancer",
            value: DcraSubsets.RectalCancer,
            filters,
            apiName: "rectalCancer",
            treatments: dcraTreatments,
            patientScoreTypes: [
                "GlobalQualityOfLiveScore",
                "PhysicalFunctioning",
                "RoleFunctioning",
                "EmotionalFunctioning",
                "CognitiveFunctioning",
                "SocialFunctioning",
                "SexualInterestFemalePatients",
                "SexualInterestMalePatients",
            ],
            patientCourses: {
                recordingTime: {
                    unit: registryLcPrefix + ".Courses.recordingTime.Unit",
                    explanation: registryLcPrefix + ".Courses.recordingTime.Explanation",
                },
                patientWithComplicatedCourse: {
                    explanation:
                        registryLcPrefix + ".Courses.patientWithComplicatedCourse.Explanation",
                },
            },
            anonymizationLimit: 20,
            lowPatientSampleLimit: 50,
        },
    ],
};
