<div class="access-denied-container">
    <h1>{{ ".Title" | lgTranslate }}</h1>
    <!-- <p [innerHTML]="'.Text' | lgTranslate"></p> -->

    <div class="logout-box" *ngIf="_getUser">
        <!-- <span>{{ ".LoginInfo" | lgTranslate: _getUser }}</span> -->
        <lg-button
            buttonClass="button--condensed button--primary"
            textLc=".Logout"
            (click)="_logout()"
        ></lg-button>
    </div>
</div>
