import { Injectable, inject } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    UrlTree,
    RouterStateSnapshot,
    Router,
    Route,
    UrlSegment,
} from "@angular/router";
import { Observable } from "rxjs";
import { defaultIfEmpty, map, tap } from "rxjs/operators";
import { DataAccessAuthorizationApiService } from "src/app/shared/services/authorization-api.service";

@Injectable({ providedIn: "root" })
export class PlmProjectGuard {
    private _router = inject(Router);
    private _dataAccesService = inject(DataAccessAuthorizationApiService);

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
        return this._checkAccess();
    }

    canLoad(
        route: Route,
        segments: UrlSegment[],
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this._checkAccess();
    }

    private _checkAccess(): Observable<boolean> {
        return this._dataAccesService.searchPermissions().pipe(
            map(permissions => {
                return permissions.length > 0;
            }),
            defaultIfEmpty(false),
            tap(hasAccess => {
                if (!hasAccess) {
                    this._router.parseUrl("access-denied");
                }
            }),
        );
    }
}
