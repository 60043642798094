import { Inject, Injectable } from "@angular/core";
import { LG_USER_INFO } from "@logex/framework/lg-application";
import { AppUser } from "../types/app-user";
import { DataAccessAuthorizationApiService } from "./authorization-api.service";
import { shareReplay } from "rxjs";

@Injectable()
export class UserProfileService {
    readonly userProfile$ = this._authorizationApiService.getProfile().pipe(shareReplay(1));

    constructor(
        private _authorizationApiService: DataAccessAuthorizationApiService,
        @Inject(LG_USER_INFO) public _lgUserInfo: AppUser,
    ) {}

    init(): void {
        this.userProfile$.subscribe(userProfile => {
            this._lgUserInfo.name = `${userProfile.firstName} ${userProfile.lastName}`;
            this._lgUserInfo.email = userProfile.email;
            this._lgUserInfo.impersonator = userProfile.impersonation?.originalUserEmail;
            this._lgUserInfo.impersonatorName = userProfile.impersonation?.originalUserName;
        });
    }
}
