import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { Auth0Guard } from "@authorization";
import { PlmProjectGuard } from "./authorized/services/plm-project-guard";
import { AccessDeniedComponent } from "./access-denied-page/access-denied.component";

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "dashboard/rectal-cancer",
    },
    {
        path: "dashboard/:subset",
        loadChildren: () => import("./authorized/authorized.module").then(m => m.AuthorizedModule),
        canLoad: [Auth0Guard, PlmProjectGuard],
        canActivate: [Auth0Guard, PlmProjectGuard],
    },
    {
        path: "access-denied",
        component: AccessDeniedComponent,
    },
    {
        path: "**",
        redirectTo: "dashboard/rectal-cancer",
        pathMatch: "full",
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
