import { INavNode } from "@logex/framework/lg-application";
import { dcraConfig } from "../configs/registries/dcra";

const subsetChildren: INavNode[] = [
    {
        path: "overview",
        lid: "APP._Navigation.Overview",
        noBreadcrumb: true,
    },
    {
        path: "treatments",
        lid: "APP._Navigation.Treatments",

        children: [
            {
                path: "",
                lid: "APP._Navigation.Overview",
            },
            {
                path: "ostomy",
                lid: "APP._Registries.DCRA.Treatments.Types.ostomy",
            },
            {
                path: "temporary-ostomy",
                lid: "APP._Registries.DCRA.Treatments.Types.anastamosisConstructedWithTemporaryOstomy",
            },
            {
                path: "no-ostomy",
                lid: "APP._Registries.DCRA.Treatments.Types.anastamosisConstructedDuringSurgeryWithoutOstomy",
            },
        ],
    },
];

export const navigation: () => INavNode[] = () =>
    [
        {
            path: "",
            id: "root",
            noBreadcrumb: true,

            children: [
                {
                    id: "dcra",
                    path: "",
                    lid: dcraConfig.name,
                    anonymous: false,

                    children: dcraConfig.subsets.map(subset => ({
                        path: "dashboard/" + subset.value,
                        lid: subset.nameLc,
                        children: subsetChildren,
                    })),
                },
                // {
                //     id: "dato",
                //     path: "dato",
                //     lid: "DATO",
                //     anonymous: false,
                // },
            ],
        },
    ] as INavNode[];
