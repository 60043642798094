import { Observable } from "rxjs";

import { ServerGatewayBase } from "@logex/framework/lg-application";

import { ConfigService } from "../services/config.service";
import { Injectable } from "@angular/core";

export type OrganizationIdentifier = "Global" | "Cic" | "Uri" | "Agb" | undefined;

export interface AuthorizationOrganization {
    organizationId: number;
    agbCode: string | null;
    cicCode: number | null;
    mrdmUri: string | null;
    name: string;
    position: string;
}

export interface AuthorizationPermission {
    product: string;
    permissions: string[];
}

export interface AuthorizationExtendedPermission
    extends AuthorizationPermission,
        AuthorizationOrganization {
    applicationInstance: string;
}

export interface AuthorizationProfile {
    accountId: number;
    email: string;
    firstName: string;
    lastName: string;
    organizations: AuthorizationOrganization[];
    isDisabled: boolean;
    isInternal: boolean;
    metadata: Record<string, string>;
    impersonation: { originalUserName: string; originalUserEmail: string };
}

@Injectable({
    providedIn: "root",
})
export class DataAccessAuthorizationApiService extends ServerGatewayBase {
    constructor(protected _configService: ConfigService) {
        super();
        const apiUrl = _configService.configuration?.authApiUrl;
        if (apiUrl != null) {
            this._setBaseUrl(apiUrl);
        } else {
            console.error("Cannot set Authorization API url based on configuration.");
        }
    }

    searchPermissions(): Observable<AuthorizationExtendedPermission[]> {
        return this._postQuery<AuthorizationExtendedPermission[]>(
            `api/v3/user/permissions/search`,
            {
                body: {
                    applicationInstances: ["ApplicationInstance.CodmanPatients.NL"],
                    products: ["Product.Dcra"],
                },
            },
        );
    }

    getProfile(): Observable<AuthorizationProfile> {
        return this._get<AuthorizationProfile>(`api/v3/user/profile`);
    }
}
