import { IUser } from "@logex/framework/lg-application";

export class AppUser implements IUser {
    id: number;
    name: string;
    roles: _.Dictionary<boolean>;
    title: string;
    userid: string;
    ziekenhuiscode: number;
    ziekenhuisnaam: string;
    impersonator = "";
    impersonatorName = "";
    email = "";

    constructor() {
        this.roles = {};
    }

    public hasPermission(role: string): boolean {
        if (role === "switch_language") {
            // hardcode to show language switch
            return true;
        }
        return this.roles[role] || false;
    }
}
