import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { mergeMap, catchError, switchMap, first } from "rxjs/operators";
import { Auth0AuthorizationService } from "./auth0-authorization.service";

@Injectable()
export class Auth0InterceptorService implements HttpInterceptor {
    constructor(private _auth: Auth0AuthorizationService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this._auth.configured) return next.handle(req);

        return this._auth.isAuthenticated$.pipe(
            first(),
            switchMap(loggedIn => (loggedIn ? this._auth.getTokenSilently() : of(null))),
            catchError(() => of(null)),
            mergeMap(token => {
                const tokenReq = token
                    ? req.clone({
                          setHeaders: { Authorization: `Bearer ${token}` },
                      })
                    : req;
                return next.handle(tokenReq);
            }),
            catchError(err => throwError(err)),
        );
    }
}
