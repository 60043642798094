import * as _ from "lodash";
import * as Filters from "@logex/framework/lg-filterset";
import { IColumnFilterDictionary, IFilterOption } from "@logex/framework/types";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { IItemClusterFilterDefinition } from "@logex/framework/ui-toolbox";

// ---------------------------------------------------------------------------------------------
// Type that removes some fields from the general filter definitions
// ---------------------------------------------------------------------------------------------
export type IFilterFactoryEntry<T> = Pick<
    T,
    Exclude<keyof T, "id" | "startGroup" | "startGroupLC">
>;

// ---------------------------------------------------------------------------------------------
//  Map wrapped filter definiton into the correct filter store
// ---------------------------------------------------------------------------------------------
export type MapFilterStore<T, X = any> = T extends IFilterFactoryEntry<
    Filters.IComboFilterDefinition<X>
>
    ? IColumnFilterDictionary
    : T extends IFilterFactoryEntry<Filters.IComboFilter2Definition<X>>
    ? IColumnFilterDictionary
    : T extends IFilterFactoryEntry<Filters.ISelectableComboFilter2Definition<X>>
    ? IColumnFilterDictionary
    : T extends IFilterFactoryEntry<Filters.ISelectedItemFilterDefinition>
    ? string | undefined
    : T extends IFilterFactoryEntry<Filters.ICheckboxFilterDefinition>
    ? boolean
    : T extends IFilterFactoryEntry<Filters.ITristateFilterDefinition>
    ? 1 | 0 | null
    : T extends IFilterFactoryEntry<Filters.IDropdownFilterDefinition<X>>
    ? number | string | null
    : T extends IFilterFactoryEntry<Filters.IRangeFilterDefinition>
    ? Filters.IRangeFilterValue
    : T extends IFilterFactoryEntry<Filters.IDateFilterDefinition>
    ? Filters.IDateFilterValue
    : T extends IFilterFactoryEntry<Filters.IInputRangeFilterDefinition>
    ? Filters.IRangeFilterValue
    : T extends IFilterFactoryEntry<IItemClusterFilterDefinition<X>>
    ? IColumnFilterDictionary
    : never;

// ---------------------------------------------------------------------------------------------
// Map wrapped filter definition back to the original type (unwrap IFilterFactoryEntry)
// ---------------------------------------------------------------------------------------------
// Note: this could be in theory done in simpler way using infer, but TS 2.8 doesn't seem to resolve the internal
// Type to anything better than "any"
export type MapFilterDefinition<T> = T extends IFilterFactoryEntry<
    Filters.IComboFilterDefinition<infer U>
>
    ? Filters.IComboFilterDefinition<U>
    : T extends IFilterFactoryEntry<Filters.IComboFilter2Definition<infer U>>
    ? Filters.IComboFilter2Definition<U>
    : T extends IFilterFactoryEntry<Filters.ISelectableComboFilter2Definition<infer U>>
    ? Filters.ISelectableComboFilter2Definition<U>
    : T extends IFilterFactoryEntry<Filters.ISelectedItemFilterDefinition>
    ? Filters.ISelectedItemFilterDefinition
    : T extends IFilterFactoryEntry<Filters.ICheckboxFilterDefinition>
    ? Filters.ICheckboxFilterDefinition
    : T extends IFilterFactoryEntry<Filters.ITristateFilterDefinition>
    ? Filters.ITristateFilterDefinition
    : T extends IFilterFactoryEntry<Filters.IDropdownFilterDefinition<infer U>>
    ? Filters.IDropdownFilterDefinition<U>
    : T extends IFilterFactoryEntry<Filters.IRangeFilterDefinition>
    ? Filters.IRangeFilterDefinition
    : T extends IFilterFactoryEntry<Filters.IDateFilterDefinition>
    ? Filters.IDateFilterDefinition
    : T extends IFilterFactoryEntry<Filters.IInputRangeFilterDefinition>
    ? Filters.IInputRangeFilterDefinition
    : T extends IFilterFactoryEntry<IItemClusterFilterDefinition<infer U>>
    ? IItemClusterFilterDefinition<U>
    : never;

// ---------------------------------------------------------------------------------------------
export type SupportedFilters =
    | IFilterFactoryEntry<Filters.IComboFilterDefinition<number>>
    | IFilterFactoryEntry<Filters.IComboFilterDefinition<string>>
    | IFilterFactoryEntry<Filters.IComboFilter2Definition<number>>
    | IFilterFactoryEntry<Filters.IComboFilter2Definition<string>>
    | IFilterFactoryEntry<Filters.IComboFilter2Definition<IFilterOption>>
    | IFilterFactoryEntry<Filters.ISelectableComboFilter2Definition<number>>
    | IFilterFactoryEntry<Filters.ISelectableComboFilter2Definition<string>>
    | IFilterFactoryEntry<Filters.ISelectedItemFilterDefinition>
    | IFilterFactoryEntry<Filters.ICheckboxFilterDefinition>
    | IFilterFactoryEntry<Filters.ITristateFilterDefinition>
    | IFilterFactoryEntry<Filters.IDropdownFilterDefinition<number>>
    | IFilterFactoryEntry<Filters.IDropdownFilterDefinition<string>>
    | IFilterFactoryEntry<Filters.IRangeFilterDefinition>
    | IFilterFactoryEntry<Filters.IDateFilterDefinition>
    | IFilterFactoryEntry<Filters.IDateFilterDefinition>
    | IFilterFactoryEntry<Filters.IInputRangeFilterDefinition>
    | IFilterFactoryEntry<IItemClusterFilterDefinition<number>>
    | IFilterFactoryEntry<IItemClusterFilterDefinition<string>>;

// ---------------------------------------------------------------------------------------------
// Typing helper to merge 2 types (A is primary, B contains defaults)
type MergeTypes<A, B> = {
    [P in keyof A]: A[P];
} & {
    [P in Exclude<keyof B, keyof A>]: B[P];
};

// ---------------------------------------------------------------------------------------------
// Base class for the filter creator (i.e. the class that's used in the chain)
// ---------------------------------------------------------------------------------------------
export class FilterFactoryCreatorBase {
    public constructor(
        protected _filterSetService: Filters.LgFilterSetService,
        protected _lgTranslateService: LgTranslateService,
    ) {
        // empty
    }

    private _definition: Filters.IFilterDefinition[] = [];
    private _startGroup = false;
    private _startGroupLC: string | null = null;

    protected _addFilter<T extends SupportedFilters, N extends string>(id: N, params: T): any {
        const entry = _.clone(params) as any;
        entry["id"] = id;

        // Getting rid of some legacy behaviour
        if (entry["visible"] === undefined) entry["visible"] = () => true;
        if (entry["main"] === undefined) entry["main"] = true;

        // Add the separately configured startgroups
        if (this._startGroup) {
            entry["startGroup"] = true;
            this._startGroup = false;
        } else if (this._startGroupLC) {
            entry["startGroupLC"] = this._startGroupLC;
            this._startGroupLC = null;
        }

        this._definition.push(entry);
        return this as any;
    }

    public startGroup(nameLC?: string): this {
        if (nameLC) {
            this._startGroupLC = nameLC;
            this._startGroup = false;
        } else {
            this._startGroup = true;
            this._startGroupLC = null;
        }
        return this;
    }

    protected _create<
        Definitions extends _.Dictionary<Filters.IFilterDefinition>,
        Filters extends Filters.IFilterList,
    >(context: any): Filters.LgFilterSet<Definitions, Filters> {
        const result = this._filterSetService.create(
            this._definition,
            this._lgTranslateService,
            context,
        ) as any;
        result.asFilterSet = () => result;
        return result;
    }
}
