import { ViewContainerRef, InjectionToken, TemplateRef } from "@angular/core";
import { Observable } from "rxjs";
import { ScrollerType } from "../scrolling";

import { LgDialogRef } from "./lg-dialog-ref";

export const LG_DIALOG_DATA = new InjectionToken<any>("LgDialogData");

export type DialogType = "normal" | "warning" | "alert";

export type IDialogShowFinalizer<T, D> = (
    options: IDialogOptions<D>,
    dialogComponentInstance: T | undefined,
    finish: (options: IDialogOptions<D> | undefined) => void
) => void;

export interface IDialogButton {
    class?: string;
    textLc?: string;
    onClick?: () => void;
    isDisabled$?: Observable<boolean>;
}

// ---------------------------------------------------------------------------------------------
//  Interfaces
// ---------------------------------------------------------------------------------------------

/*
TODO: 
- input stream for hide
- input stream for ready
- output streams for after show / after hide
*/

// ---------------------------------------------------------------------------------------------
//  Parameters
// ---------------------------------------------------------------------------------------------

/**
 * Configuration for the dialog.show function.
 */
export interface IDialogOptions<T = any> {
    data?: T;
    /**
     * The title of the dialog window. Defaults to "Dialog"
     */
    title?: string;

    /**
     * The title icon (css class name) of the dialog. Defaults to none
     */
    icon?: string;

    /**
     * URL to help page for the dialog. Defaults to none
     */
    helpUrl?: string;

    /**
     * Specifies whether the dialog can be closed with the close button. Defaults to true
     */
    allowClose?: boolean;

    /**
     * Specifies a callback that will be called when the dialog is closing
     */
    onClose?: (dialog: LgDialogRef<any>) => void;

    /**
     * Specifies a callback that can veto the dialog's closing, using the X button (this won't affect close by api call). If the callback returns false, the dialog won't be closed
     */
    tryClose?: (dialog: LgDialogRef<any>) => boolean;

    /**
     * Specifies the "api" of visible dialog that the new one is related to. The code will try to arrange them visually side by side
     */
    relatedTo?: LgDialogRef<any>;

    /**
     * Specifies the minimum height of the dialog window. This is useful when the content is loaded on demand, and we know it will be big
     */
    minHeight?: number;

    viewContainerRef?: ViewContainerRef;

    /**
     * Specifies the dialog type. Currently supported values are "wide", "column" and "regular" (which is default)
     * When type equals to "column", create the large right-column dialog. For "wide" create flexible height dialog with the width as the right column
     */
    type?: DialogType;

    /**
     * Specifies additional class or classes to be applied to the dialog (in addition to those determined by the type)
     */
    dialogClass?: string;

    /**
     * Specifies additional class or classes to be applied to the dialog's body
     */
    dialogBodyClass?: string;

    /**
     * If true, pressing on "ESC"-button calls tryClose
     */
    closeOnEsc?: boolean;

    /**
     * Specifies, if the dialog can be closed by clicking on the overlay. Defaults to false. The usual validations
     * (tryClose) apply.
     */
    closeOnOverlayClick?: boolean;

    /**
     * Custom template that can be inserted after the dialog title
     */
    dialogHeaderTemplate?: TemplateRef<any>;

    ready?: Observable<boolean>;

    /**
     * Specifies, if the dialog should automatically close on navigation. This bypasses the usual tryClose verification,
     * but will call onClose(). Note that when dialog has this flag, it will NOT automatically force closure of all dialogs
     * above it in the stack.
     * Defaults to true
     */
    forceCloseOnNavigation?: boolean;

    /**
     * Specifies whether the dialog can be maximized with the maximize button. Defaults to false
     */
    allowMaximize?: boolean;

    /**
     * Specifies buttons in bottom panel of the dialog.
     */
    dialogButtons?: IDialogButton[];

    /**
     * Specifies scroller type of the dialog content. Defaults to vertical.
     */
    scrollerType?: ScrollerType;
}

export type OverridableDialogOptions = Omit<
    IDialogOptions,
    "onClose" | "tryClose" | "ready" | "relatedTo" | "viewContainerRef"
>;

/**
 * This is the interface that the DialogHub expects the specified controller to implement. For details of the
 * meaning of all the properties, please see the documentation for DialogService and IDialogOptions.
 *
 * Notice that all the properties are optional (however if there is no activate(), the show won't return anything. Most
 * of the configuration options ( title, icon, allowClose etc) can be present either as property, or as a method.
 * When method is used.
 *
 * The controller can (and should) be injected (using standard angular or $injectFields) with dialogApi and $scope
 *
 * Note that all the properties are "sampled" at the time of the dialog show (and every time dialog is shown),
 * but are not watched: change of the title won't be reflected on a visible dialog.
 */
export interface IDialogComponent<T, R = any> {
    /**
     * This method will be called right after the dialog is shown. It receives the dialog api, and then
     * all the arguments of the show, apart from the scope
     */
    _activate?: () => void;

    /**
     * If present, this method will be called right before the dialog is shown, and it has a chance to override
     * any of the defaults passed to it (as well as completely ignore them and return its own configuration).
     */
    _configure?: (options: IDialogOptions) => IDialogOptions;

    /**
     * Property or method specifying the title of the dialog. Note that the title is not dynamically when
     * the property changes (unless the dialog is shown the second time)
     */
    _title?: string | (() => string);

    /**
     * Property or method specyfing the icon of the dialog. See also title
     */
    _icon?: string | (() => string);

    /**
     * Property or method specyfing help URL
     */
    _helpUrl?: string | (() => string);

    /**
     * Property or method specyfing, whether the dialog should have the close button. See also title
     */
    _allowClose?: boolean | (() => boolean);

    /**
     * If present, the method will be called when the dialog is closing
     */
    _onClose?: (dialog: LgDialogRef<T, R>) => void;

    /**
     * If present, the method will be called when the dialog is trying to close using the default action (close
     * button).
     */
    _tryClose?: (dialog: LgDialogRef<T, R>) => boolean;

    /**
     * Property or method specyfing, whether the dialog should have the maximize button. See also title
     */
    _allowMaximize?: boolean | (() => boolean);

    /**
     * Property or method specifying the related dialog api.
     */
    _relatedTo?: LgDialogRef<any> | (() => LgDialogRef<any>);

    /**
     * Property or method specifying the dialog's minimum height.
     */
    _minHeight?: number | (() => number);

    /**
     * Property or method specifying the dialog's type. Defaults to "regular"
     */
    _dialogType?: DialogType | (() => DialogType);

    /**
     * Property or method specifying the dialog's type. Defaults to "regular"
     *
     * @deprecated Please replace with _dialogType
     */
    dialogType?: string | (() => string);

    /**
     * Property or method specifying additional classes to be applied to the dialog
     */
    _dialogClass?: string | (() => string);

    /**
     * Property or method specifying additional classes to be applied to the dialog's body
     */
    _dialogBodyClass?: string | (() => string);

    _closeOnEsc?: boolean | (() => boolean);

    /**
     * Specifies, if the dialog can be closed by clicking on the overlay. Defaults to false. The usual validations
     * (tryClose) apply.
     */
    _closeOnOverlayClick?: boolean | (() => boolean);

    _ready?: Observable<boolean> | (() => Observable<boolean>);

    _initializationDone?: Observable<void>;

    _dialogHeaderTemplate?: TemplateRef<any>;

    /**
     * Specifies, if the dialog should automatically close on navigation. This bypasses the usual tryClose verification,
     * but will call onClose(). Note that when dialog has this flag, it will NOT automatically force closure of all dialogs
     * above it in the stack.
     * Defaults to true
     */
    _forceCloseOnNavigation?: boolean | (() => boolean);

    /**
     * Specifies buttons in bottom panel of the dialog.
     */
    _dialogButtons?: IDialogButton[];

    /**
     * Specifies scroller type of the dialog content. Defaults to vertical.
     */
    _scrollerType?: ScrollerType;
}
