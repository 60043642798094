import { Injectable, inject } from "@angular/core";
import {
    Router,
    ActivatedRoute,
    NavigationEnd,
    Route,
    Params,
    ActivatedRouteSnapshot
} from "@angular/router";

import { BehaviorSubject, Observable } from "rxjs";
import { filter, map, startWith } from "rxjs/operators";

// Based on https://github.com/stomo21/ng-router-state-params
// TODO: add primary router filter on all elements?

interface IRouteInfo {
    config: Route;
    snapshot: ActivatedRouteSnapshot;
}

/**
 * getRoute() : Observable<ActivatedRoute> - contains the ActivatedRoute object
 * getUrl() : Observable<string> - url component, does not include domain
 * getConfig() : Observable<object> - route config options for the active route (contains title if provided)
 * getParams() : Observable<object> - all params and values for current route (duplicate names overwritten)
 * getPath() : Observable<string> - returns the active path string
 *
 * getRouteValue() : ActivatedRoute - contains the ActivatedRoute object
 * getUrlValue() : string - url component, does not include domain
 * getConfigValue() : object - route config options for the active route (contains title if provided)
 * getParamsValue() : object - all params and values for current route (duplicate names overwritten)
 * getPathValue() : string - active path
 */
@Injectable({ providedIn: "root" })
export class LgRouterStateService {
    private _activatedRoute = inject(ActivatedRoute);
    private _router = inject(Router);

    private _url: string;
    private readonly _urlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    private _path: string;
    private readonly _pathSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    private _route: ActivatedRoute;
    private readonly _routeSubject: BehaviorSubject<ActivatedRoute> =
        new BehaviorSubject<ActivatedRoute>(null);

    private _config: Route;
    private readonly _configSubject: BehaviorSubject<Route> = new BehaviorSubject<Route>(null);

    private _params: Params;
    private readonly _paramsSubject: BehaviorSubject<Params> = new BehaviorSubject<Params>(null);

    private _deepestSnapshot: ActivatedRouteSnapshot;
    private readonly _deepestSnapshotSubject: BehaviorSubject<ActivatedRouteSnapshot> =
        new BehaviorSubject<ActivatedRouteSnapshot>(null);

    constructor() {
        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                startWith(null),
                map(_ => this._router.routerState.root),
                map(route => {
                    const allRoutes: IRouteInfo[] = [];

                    allRoutes.push({
                        config: route.routeConfig,
                        snapshot: route.snapshot
                    });

                    while (route.firstChild) {
                        route = route.firstChild;
                        allRoutes.push({
                            config: route.routeConfig,
                            snapshot: route.snapshot
                        });
                    }

                    return allRoutes;
                }),
                filter(data => data[data.length - 1].snapshot != null)
            )
            .subscribe(data => {
                this._route = this._activatedRoute;
                this._url = this._router.url;
                this._config = data[data.length - 1].config;
                if (data[data.length - 1].snapshot.outlet === "primary") {
                    this._deepestSnapshot = data[data.length - 1].snapshot;
                }

                const paths: string[] = [];
                this._params = {};
                data.forEach(val => {
                    if (val && val.snapshot && val.snapshot.params) {
                        this._params = Object.assign(this._params, val.snapshot.params.value);
                    }
                    if (val && val.config && val.config.path) {
                        paths.push(val.config.path);
                    }
                });

                this._path = paths.join("/");
                this._routeSubject.next(this._route);
                this._urlSubject.next(this._url);
                this._configSubject.next(this._config);
                this._paramsSubject.next(this._params);

                if (data[data.length - 1].snapshot.outlet === "primary") {
                    this._deepestSnapshotSubject.next(this._deepestSnapshot);
                }
            });
    }

    public route(): Observable<ActivatedRoute> {
        return this._routeSubject.asObservable();
    }

    public path(): Observable<string> {
        return this._pathSubject.asObservable();
    }

    public url(): Observable<string> {
        return this._urlSubject.asObservable();
    }

    public config(): Observable<Route> {
        return this._configSubject.asObservable();
    }

    public params(): Observable<Params> {
        return this._paramsSubject.asObservable();
    }

    public deepestSnapshot(): Observable<ActivatedRouteSnapshot> {
        return this._deepestSnapshotSubject.asObservable();
    }

    public get currentRoute(): ActivatedRoute | null {
        return this._route;
    }

    public get currentPath(): string {
        return this._path || "";
    }

    public get currentUrl(): string {
        return this._url;
    }

    public get currentConfig(): Route {
        return this._config || {};
    }

    public get currentParams(): Params {
        return this._params;
    }

    public get currentDeepestSnapshot(): ActivatedRouteSnapshot {
        return this._deepestSnapshot;
    }

    public isActivePath(path: string, exact: boolean): boolean {
        if (this._path) {
            if (exact) {
                if (path === this._path || path === "/" + this._path) {
                    return true;
                }
            } else if (this._path.indexOf(path) === 0 || ("/" + this._path).indexOf(path) === 0) {
                return true;
            }
        }
        return false;
    }
}
