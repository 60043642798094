import { Observable, Subject, Subscription } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

import { IDialogOptions, OverridableDialogOptions } from "./lg-dialog.types";
import { LgDialogHolderComponent } from "./lg-dialog-holder.component";
import { IOverlayResultApi } from "../lg-overlay/lg-overlay.service";

export class LgDialogRef<T, R = any> {
    // an instance of a component which is passed in to lgDialogFactory as componentOrTemplateRef
    // and is used as a content of a dialog
    public componentInstance: T;

    public get visible(): boolean {
        return this._visible;
    }

    public get options(): IDialogOptions {
        return this._options;
    }

    public get isTop(): boolean {
        return this._overlay.isTop();
    }

    private _dying = false;
    private readonly _beforeClosed = new Subject<R>();
    private readonly _afterClosed = new Subject<R>();
    private _dialogResult: R;
    private _visible = false;
    private _escapeKeyPressSubscription: Subscription = null;
    private _backdropClickCloseSubscription: Subscription = null;

    constructor(
        readonly id: string,
        private _options: IDialogOptions,
        private _overlay: IOverlayResultApi,
        // an instance of LgDialogHolderComponent which is attached to an overlay
        public _holderInstance: LgDialogHolderComponent
    ) {
        this._holderInstance._requestClose
            .pipe(takeUntil(this._beforeClosed))
            .subscribe(() => this._tryClose());
    }

    public close(dialogResult?: R, immediately?: boolean): void {
        if (this._dying) return;

        if (!this.visible) {
            console.warn(`The dialog ${this.id} is already hidden`);
            return;
        }

        this._dialogResult = dialogResult;
        this._dying = true;

        this._beforeClosed.next(this._dialogResult);
        this._beforeClosed.complete();

        if (this._options.onClose) {
            this._options.onClose(this);
        }

        this._visible = false;
        this._removeEscapeKeyCloseHandler();
        this._removeBackdropClickCloseHandler();

        if (immediately) {
            this._overlay.hide();
            this._afterClosed.next(this._dialogResult);
            this._afterClosed.complete();
        } else {
            this._overlay.overlayRef.detachBackdrop();
            this._holderInstance
                .hide()
                .pipe(take(1))
                .subscribe(() => {
                    this._overlay.hide();
                    this._afterClosed.next(this._dialogResult);
                    this._afterClosed.complete();
                });
        }
    }

    public updateOptions(options: OverridableDialogOptions): void {
        Object.assign(this._options, options);
        this._holderInstance.updateOptions(options);

        const closeOnEsc = this._options.closeOnEsc;
        if (closeOnEsc && !this._escapeKeyPressSubscription) {
            this._setEscapeKeyCloseHandler();
        } else if (!closeOnEsc && this._escapeKeyPressSubscription) {
            this._removeEscapeKeyCloseHandler();
        }
        const closeOnOverlayClick = this._options.closeOnOverlayClick;
        if (closeOnOverlayClick && !this._backdropClickCloseSubscription) {
            this._setBackdropClickCloseHandler();
        } else if (!closeOnOverlayClick && this._backdropClickCloseSubscription) {
            this._removeBackdropClickCloseHandler();
        }
    }

    public isTopLayer(): boolean {
        return this.visible && this._overlay.isTop();
    }

    public center(): void {
        this._holderInstance._center(true);
    }

    public maybeCenter(overridePosition = false): void {
        this._holderInstance._maybeCenter(overridePosition);
    }

    public beforeClosed(): Observable<R | undefined> {
        return this._beforeClosed.asObservable();
    }

    public afterClosed(): Observable<R | undefined> {
        return this._afterClosed.asObservable();
    }

    public keydownEvents(): Observable<KeyboardEvent> {
        return this._overlay.overlayRef.keydownEvents().pipe(takeUntil(this._beforeClosed));
    }

    private _tryClose(): void {
        if (this._options.tryClose) {
            if (!this._options.tryClose(this)) return;
        }
        this.close();
    }

    public finalizedOptions(options: IDialogOptions): void {
        this._options = options;
        if (this._options.closeOnEsc) {
            // it's expected that finalizedOptions() is called once per dialogRef creation
            this._setEscapeKeyCloseHandler();
        }
        if (this._options.closeOnOverlayClick) {
            this._setBackdropClickCloseHandler();
        }
        this._visible = true;
    }

    // More descriptive alias for finalizedOptions()
    public applyFinalizedOptions = this.finalizedOptions;

    private _setEscapeKeyCloseHandler() {
        this._escapeKeyPressSubscription = this.keydownEvents().subscribe((e: KeyboardEvent) => {
            if (e.key?.toLowerCase() !== "escape") {
                return;
            }

            if (
                document.activeElement instanceof HTMLInputElement ||
                document.activeElement instanceof HTMLTextAreaElement
            ) {
                document.activeElement.blur();
            } else {
                this._tryClose();
            }
        });
    }

    private _removeEscapeKeyCloseHandler() {
        this._escapeKeyPressSubscription?.unsubscribe();
        this._escapeKeyPressSubscription = null;
    }

    private _setBackdropClickCloseHandler() {
        this._backdropClickCloseSubscription = this._overlay.overlayRef
            .backdropClick()
            .pipe(takeUntil(this._beforeClosed))
            .subscribe(() => {
                this._tryClose();
            });
    }

    private _removeBackdropClickCloseHandler() {
        this._backdropClickCloseSubscription?.unsubscribe();
        this._backdropClickCloseSubscription = null;
    }
}
