import { Injectable } from "@angular/core";
import { AppConfiguration, RegistryConfiguration, EnvConfiguration } from "./app-configuration";

@Injectable()
export class ConfigService {
    configuration: AppConfiguration;
    configurationPromise: Promise<AppConfiguration>;
    private _resolve: (value: AppConfiguration) => void;

    constructor() {
        this.configurationPromise = new Promise<AppConfiguration>(resolve => {
            this._resolve = resolve;
        });
    }

    _setConfiguration(envConfig: EnvConfiguration, registryConfig: RegistryConfiguration): void {
        let apiUrl: string;
        let authApiUrl: string;
        if (envConfig.environment === "prod") {
            apiUrl = `https://${registryConfig.abbreviation}.valuebase.nl/patientslikeme/`;
            authApiUrl = `https://user-authorization-api.mrdm.eu/`;
        } else {
            apiUrl = `https://${registryConfig.abbreviation}-${envConfig.environment}.valuebase.nl/patientslikeme/`;
            const environment = envConfig.environment === "test" ? "test" : "accept";
            authApiUrl = `https://user-authorization-api-${environment}.mrdm.eu/`;
        }

        this.configuration = {
            ...envConfig,
            ...registryConfig,
            registry: registryConfig.abbreviation,
            auth0: { redirectUri: location.origin, ...envConfig.auth0, ...registryConfig.auth0 },
            apiUrl,
            serviceDeskMail: registryConfig.serviceDeskMail,
            serviceDeskWeb: registryConfig.serviceDeskWeb,
            authApiUrl,
        };
        this._resolve(this.configuration);
        this._resolve = null;
    }
}
