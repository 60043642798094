import { Injectable } from "@angular/core";
import { Dictionary } from "lodash";
import { LgLocalizationSettings, LocalizationJson } from "@logex/framework/lg-localization";

export const defaultLanguage = "nl-NL";

@Injectable()
export class AppLocalizationSettings implements LgLocalizationSettings {
    readonly bootstrapDone: Promise<void>;

    readonly languages: _.Dictionary<LocalizationJson[]> = {};

    private _preferredLanguage = defaultLanguage;

    constructor() {
        const preferredLanguage = localStorage.getItem("preferredLanguage");
        if (preferredLanguage != null) {
            this._preferredLanguage = preferredLanguage;
        }

        this.bootstrapDone = Promise.resolve();
    }

    get fallbackLanguage(): string {
        return defaultLanguage;
    }

    get preferredLanguage(): string {
        return this._preferredLanguage;
    }

    get availableLanguages(): string[] {
        return ["en-GB", "nl-NL"];
    }

    get locale(): string {
        return this.preferredLanguage;
    }

    get currency(): string {
        return "EUR";
    }

    addStrings(lang: string, strings: Dictionary<LocalizationJson>): void {
        if (!this.languages[lang]) this.languages[lang] = [];
        this.languages[lang].push(strings);
    }

    setStrings(lang: string, strings: Dictionary<any>): void {
        this.languages[lang] = [strings];
    }

    setPreferredLanguage(lang: string): void {
        this._preferredLanguage = lang;
    }

    switchCurrentLanguage(lang: string): void {
        localStorage.setItem("preferredLanguage", lang);
        window.location.reload();
    }
}
