import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { first } from "rxjs/operators";

import { LgMatTrackingService, LG_MATOMO_CONFIGURATION } from "@logex/framework/lg-application";

import { MatomoConfiguration } from "src/app/shared/services/app-configuration";
import { ConfigService } from "src/app/shared/services/config.service";
import { MatomoSharedConfiguration } from "../../shared/matomo-shared-configuration";
import { Auth0AuthorizationService } from "@authorization";

@Injectable({
    providedIn: "root",
})
export class MatomoUtilService {
    private _routerEventsSubscription?: Subscription;
    constructor(
        private _matTrackingService: LgMatTrackingService,
        @Inject(LG_MATOMO_CONFIGURATION)
        private _matomoSharedConfiguration: MatomoSharedConfiguration,
        private _configService: ConfigService,
        private _auth0Authorization: Auth0AuthorizationService,
        private _http: HttpClient,
        private _router: Router,
    ) {}

    getMatomoConfig$(): Observable<MatomoConfiguration> {
        const configPath = "assets/config/matomo-settings.json";
        return this._http.get<MatomoConfiguration>(configPath);
    }

    init(): void {
        const registry = this._configService.configuration?.registry;
        let siteId = "";

        this.getMatomoConfig$()
            .pipe(first())
            .subscribe((config: MatomoConfiguration) => {
                const env = this._configService.configuration.environment;
                siteId = this._getConfigSiteId(config, env, registry);

                if (siteId) {
                    this._matomoSharedConfiguration.siteId = +siteId;
                    this._matTrackingService.init();
                    this._routerEventsSubscription?.unsubscribe();
                    this._routerEventsSubscription = this._router.events.subscribe(event => {
                        if (event instanceof NavigationEnd) {
                            this._matTrackingService.pageChange();
                        }
                    });
                    this._setMatomoCustomDimensions(config, registry);
                    this._matTrackingService.pageChange();
                }
            });
    }

    private _getConfigSiteId(
        matomoConfiguration: MatomoConfiguration,
        env: string,
        registry?: string,
    ): string {
        const matomoRegistry = matomoConfiguration.matomoRegistries.find(
            item => registry === item.registry,
        );
        if (matomoRegistry) {
            switch (env) {
                case "prod":
                    return matomoRegistry.envSiteId.prod;
                case "test":
                    return matomoRegistry.envSiteId.test;
                default:
                    return "";
            }
        } else return "";
    }

    private _setMatomoCustomDimensions(
        matomoConfiguration: MatomoConfiguration,
        registry?: string,
    ): void {
        const customDimension = matomoConfiguration.customDimension;
        const registryId = +customDimension.registry;
        const providerId = +customDimension.provider;
        const providerRegistryId = +customDimension.providerRegistry;
        const registryProviderId = +customDimension.registryProvider;
        const positionId = +customDimension.position;

        if (registry) {
            this._matTrackingService.setCustomDimension(registryId, registry);
        }

        this._auth0Authorization.userProfile$.pipe(first()).subscribe(userProfile => {
            const provider =
                userProfile && userProfile["https://mrdm.io/healthcareserviceproviders"]?.[0];
            if (provider) {
                this._matTrackingService.setCustomDimension(providerId, provider);
            }
            if (registry && provider) {
                const PROVIDER_REGISTRY = `${provider} (${registry})`;
                const REGISTRY_PROVIDER = `${registry} (${provider})`;
                this._matTrackingService.setCustomDimension(providerRegistryId, PROVIDER_REGISTRY);
                this._matTrackingService.setCustomDimension(registryProviderId, REGISTRY_PROVIDER);
            }
            const position = userProfile && userProfile["https://mrdm.io/position"];
            if (position) {
                this._matTrackingService.setCustomDimension(positionId, position);
            }
            this._matTrackingService.pageChange();
        });
    }
}
