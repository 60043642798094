export class PlmAppConfiguration {
    instance: string;
    environment: string;
    mamangementUrl: string;
    applicationRoot: string;
    domain: string;
    clientId: string;
    audience: string;
    scope: string;
}
