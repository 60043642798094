import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ConfigService } from "./config.service";
import { RegistryConfiguration, EnvConfiguration } from "./app-configuration";
import { combineLatest } from "rxjs";

@Injectable({ providedIn: "root" })
export class AppStartupService {
    constructor(
        private _http: HttpClient,
        private _configService: ConfigService, // @Inject(UM_APP_CONFIG) private _appConfig: AdminAppConfiguration, // @Inject(AUTH0_CONFIG) private _authConfig: Auth0Config, // @Inject(GLI_API_CONFIGURATION) private _apiConfig: GliApiConfiguration
    ) {}

    initialize(): () => Promise<void> {
        return async () => {
            await this._loadConfig();
        };
    }

    private async _loadConfig(): Promise<void> {
        combineLatest([
            this._http.get<EnvConfiguration>(environment.envConfig),
            this._http.get<RegistryConfiguration>(environment.registryConfig),
        ])
            .pipe(tap(([env, registry]) => this._configService._setConfiguration(env, registry)))
            .toPromise();
    }
}
