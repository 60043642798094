import { NgModule, ModuleWithProviders } from "@angular/core";

@NgModule({
    declarations: [],
    imports: [],
    exports: [],
})
export class GliAuthorizationModule {
    static forRoot(): ModuleWithProviders<GliAuthorizationModule> {
        return {
            ngModule: GliAuthorizationModule,
            providers: [
                // {
                //     provide: AUTH0_CONFIG,
                //     useValue: config
                // },
                // {
                //     provide: HTTP_INTERCEPTORS,
                //     useClass: Auth0InterceptorService,
                //     multi: true
                // }
            ],
        };
    }
}
