import { Component } from "@angular/core";
import { MatomoUtilService } from "./authorized/services/matomo-util.service";
import { UserProfileService } from "./shared/services/user-profile.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent {
    title = "patients-like-me";

    constructor(
        private _matomoUtilService: MatomoUtilService,
        private _userProfileService: UserProfileService,
    ) {
        this._matomoUtilService.init();
        this._userProfileService.init();
    }
}
