import {
    IMatomoCommonDimensionIds,
    IMatomoConfiguration,
    MatomoStatsUrl,
} from "@logex/framework/lg-application";

export class MatomoSharedConfiguration implements IMatomoConfiguration {
    statUrl = MatomoStatsUrl.MRDM;
    siteId: number;
    commonDimensionIds: IMatomoCommonDimensionIds = {};
    registry?: string;
    autoInit = false;
    autoTrack = false;

    doNotDoMatTracking(): boolean {
        return false;
    }
}
