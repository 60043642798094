export function createCustomEvent<T extends CustomEvent>(
    type: string,
    detail: any,
    bubbles?: boolean,
    cancelable?: boolean
): T {
    let result: CustomEvent;
    if (typeof (window as any).CustomEvent === "function") {
        result = new CustomEvent(type, { bubbles, cancelable, detail } as CustomEventInit<any>);
    } else {
        result = document.createEvent("CustomEvent");
        result.initCustomEvent(type, bubbles, cancelable, detail);
    }
    return result as any;
}
